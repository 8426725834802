<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t('title.user_list') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="params.search"
          append-icon="mdi-magnify"
          :label="$t('input.search')"
          single-line
          hide-details
          clearable
          @input="fetchListUsers(params)"
          class="mr-5"
      />
      <v-btn color="primary" @click="refreshBalance" v-if="userp && (userp.role_id == '61829dc7e64b1b17688326d5' || userp.role_id == '61829dd1e64b1b17688326df')">
        REFRESH
      </v-btn>
      <v-btn color="error"
             @click="dialogDelete = !dialogDelete"
             :disabled="dataDelete.length < 1" v-if="checkUserPermission('DELETE')">
        {{ $t('button.delete_all') }}
      </v-btn>
      
      <a href="https://t.me/suportsbc2888" v-if="userp && (userp.role_id == '61829dc7e64b1b17688326d5' || userp.role_id == '61829dd1e64b1b17688326df')">
      <v-btn class="ml-5" style="font-weight: bold; color: white; background-color: chocolate;padding: 10px;" @click="onCreateDialog" v-if="checkUserPermission('CREATE')"> SUPPORT 24/7</v-btn>
            <!-- SUPPORT 24/7 -->
          </a>

      <v-btn class="ml-5" color="primary" @click="onCreateDialog" v-if="checkUserPermission('CREATE')">{{ $t('button.create') }}</v-btn>

      <dialog-delete
          :dialog-delete="dialogDelete"
          :darkmode="darkmode"
          v-on:closeDelete="closeDelete"
          v-on:deleteItemConfirm="deleteItemConfirm"
      />
    </v-toolbar>
    <loader v-if="this.loader == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </div>
  
</template>

<script>
import {mapActions} from "vuex";
import DialogDelete from "../../../components/shared/notify/dialogDelete.vue";

export default {
  components: {DialogDelete},
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission','userp'],
  data() {
    return {
      dialogDelete: false,
      loader: false
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },

    async deleteItemConfirm() {
      await this.deleteUser({
        vm: this,
        data: {deleteItems: this.dataDelete}
      });
      this.fetchListUsers(this.params)
      this.dialogDelete = false;
    },
    async refreshBalance() {
      this.loader = true;
      // await this.$request
      //     .post({
      //     url: "sync-module/getSyncBalanceAgent",
      //     data: {},
      //     })
      //     .then((res) => {
      //       console.log("res",res.data);
      //     if (res.data.code) {
      //         this.loader = false;
      //         this.$emit("child-checkbox", this.loader);
      //     }else{
      //         this.loader = false;
      //     }
      //     });
          this.$emit("child-checkbox", this.loader);
          this.loader = false;
    },
    async onCreateDialog() {
      await this.fetchAllRolesDependOnUser();
      this.fetchPopUp(true)
    },
    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", ["fetchPopUp", "fetchListUsers", "deleteUser"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
